@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    font-family: "Noto Sans", sans-serif !important;
    scroll-behavior: smooth;
    scroll-padding-top: 3.5rem;
}

body {
    margin: 0;
    letter-spacing: 0.25px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Mui-selected {
    font-weight: 400 !important;
}

.MuiAutocomplete-root {
    margin: 0 auto;
}

.leaflet-control-attribution {
    display: none;
}

div.leaflet-control-container {
    position: absolute;
    right: 2rem;
    bottom: 8rem;
}

.leaflet-container {
    background: transparent !important;
}

.leaflet-left .leaflet-control {
    margin: 0 !important;
}

div.leaflet-touch .leaflet-bar {
    border: none;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.leaflet-touch .leaflet-bar a:first-of-type,
.leaflet-touch .leaflet-bar a:last-of-type {
    font-weight: 400;
    font-size: x-large;
    width: 2rem;
    border: 1px solid #1e2dbe;
    border-radius: 0.3rem !important;
    background-color: white;
    color: #1e2dbe;
    height: 2rem;
}

.leaflet-touch .leaflet-bar a:first-of-type:focus,
.leaflet-touch .leaflet-bar a:last-of-type:focus {
    color: #ffffff;
    background-color: #1e2dbe !important;
}

fieldset {
    border-color: transparent !important;
}

.MuiInputBase-root.MuiOutlinedInput-root {
    margin: 0;
    max-width: 300px;
    width: 100%;
}

.MuiInputBase-input.MuiOutlinedInput-input:focus {
    border-radius: 0;
}

.MuiTextField-root
    > div.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1kne6kk-MuiAutocomplete-root
    .MuiOutlinedInput-root {
    padding-right: 0;
}

.MuiAutocomplete-hasPopupIcon.css-cm7clq-MuiAutocomplete-root
    .MuiOutlinedInput-root {
    padding-right: 0 !important;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-family: "Noto Sans", sans-serif !important;
    color: #230050 !important;
    font-size: 14px;
    font-weight: 400;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-family: "Noto Sans", sans-serif !important;
    color: #230050 !important;
    font-size: 14px;
    font-weight: 400;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    font-family: "Noto Sans", sans-serif !important;
    color: #230050 !important;
    font-size: 14px;
    font-weight: 400;
}

/* Scrollbar */
.scrollable::-webkit-scrollbar {
    width: 0.5rem;
}

.scrollable::-webkit-scrollbar-track {
    background: #f9f9f9;
    margin: 0.4rem;
    border-radius: 1rem;
}

.scrollable::-webkit-scrollbar-thumb {
    background-color: #230050;
    border: 0.18rem solid #f9f9f9;
    border-radius: 1rem;
}

/* Trends chart */
#trendsChart {
    margin-top: 4rem;
    width: 830px;
    border: 1px solid #bedcfa;
    border-radius: 0.5rem;
    padding: 1rem;
}

.tooltip {
    position: relative;
}

.highcharts-area {
    fill-opacity: 0.4;
}

.tooltip-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.3rem;
}

.tooltip-header img {
    cursor: pointer;
}

.tooltip-header img:hover + p {
    display: block;
}

.tooltip-header p {
    top: -4rem;
    position: absolute;
    color: #230050;
    background-color: #ebf5fd;
    font-size: 12px;
    font-weight: 300;
    box-shadow: 0px 4px 4px 0px rgba(37, 37, 37, 0.15),
        2px -2px 4px 0px rgba(0, 0, 0, 0.05);
    border-radius: 0.5rem;
    padding: 1rem;
    width: 26rem;
    display: none;
}

.tooltip-header > h2 {
    font-size: 0.6rem;
    font-weight: 600;
}

.sp-coverage-text,
.sp-coverage-text > div:first-child,
.sp-coverage-text > div:last-child,
.sp-coverage-value > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sp-coverage-text {
    padding-bottom: 0.5rem;
    border-bottom: #bedcfa solid 0.13rem;
    width: 11.25rem;
    margin-bottom: 0.5rem;
}

.sp-coverage-text > div:first-child > span {
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: #1e2dbe;
}

.sp-coverage-text > div:first-child > p {
    width: 6.875rem;
}

.sp-coverage-text > div:first-child > p,
.sp-coverage-text > div:last-child > span {
    margin-left: 0.3rem;
}

.sp-coverage-value > div:first-child {
    padding-bottom: 0.3rem;
}

/* Country profile chart */
div.trend-chart div.highcharts-container {
    width: 51.8rem !important;
    height: 25.8rem !important;
}

.highcharts-container:not("#trendsChart") {
    width: 280px !important;
    height: 280px !important;
}

.highcharts-credits {
    display: none;
}

.highcharts-title span {
    height: 100%;
    display: block;
    position: absolute;
    border: 0.5px dashed white;
    left: -15%;
    top: 90%;
}

.highcharts-title strong {
    display: inline-block;
    width: 1.688rem;
    height: 1.688rem;
    border: 1px solid #82afdc;
    background-color: #ebf5fd;
    border-radius: 50%;
    font-weight: 600;
    padding: 0.4rem 0.4rem 0.4rem 0.2rem;
}

.highcharts-subtitle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transform: translate(-50%);
    font-size: 2rem !important;
    font-weight: 900;
    left: 50% !important;
    top: 38% !important;
    color: #230050 !important;
}

#pieChart > div:first-of-type,
#pieChart > div:first-of-type > div:first-of-type {
    overflow: visible !important;
}

/* Country profile btns */
.socialProtectionBtns:not(.active):hover svg,
.socialProtectionBtns:not(.active):hover > div div span {
    filter: invert(36%) sepia(76%) saturate(764%) hue-rotate(188deg)
        brightness(89%) contrast(99%);
}

/* Radio btns */
.radio-btns label {
    width: 100%;
    margin: 0;
    color: #230050;
}

.radio-btns label span {
    font-weight: 600;
    font-size: 0.875rem;
    padding-right: 0.25rem;
}

.radioLegendBtns label {
    margin: 0;
}

.radioLegendBtns label span {
    padding-right: 0.25rem;
}

.radioLegendBtns label span:last-of-type {
    font-weight: 600;
    font-size: 0.75rem;
    color: #230050;
}

/* Popup message */
.tooltip {
    position: relative;
    display: inline-block;
}

#tooltip button,
#tooltip button {
    width: 0.5rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

#tooltip button:first-of-type {
    left: -2rem;
}

#tooltip button:last-of-type {
    right: -2rem;
}

.tooltip .tooltip-text {
    visibility: hidden;
    color: #23004f;
    font-size: 0.8rem;
    font-weight: 300;
    background-color: #ebf5fd;
    padding: 0.5rem;
    box-shadow: 0px 4px 4px 0px rgba(37, 37, 37, 0.15),
        2px -2px 4px 0px rgba(0, 0, 0, 0.05);
    border-radius: 0.3rem;
    position: absolute;
    z-index: 402;
}

.tooltip:hover .tooltip-text {
    visibility: visible;
}

/* hubspot Contact  */
/* .hs-input ::-webkit-input-placeholder {
    color: #230050;
    background-color: transparent;
    border: 1px solid #230050;
}

.hs-input :-moz-placeholder {
    color: #230050;
    background-color: transparent;
    border: 1px solid #230050;
}

.hs-input ::placeholder {
    color: #230050;
    background-color: transparent;
    border: 1px solid #230050;
} */

.no-list.hs-error-msgs.inputs-list {
    margin: 0.5rem 0;
    color: #fb636f;
    font-size: 12px;
}

.hs-input {
    background-color: transparent;
    width: 100%;
    border: 1px solid #230050;
    border-radius: 0.25rem;
    font-weight: 300;
    padding-block: 0.5rem !important;
}

.hs-button.primary.large {
    margin: 0;
}

fieldset {
    padding-bottom: 1rem !important;
}

label span {
    color: #230050;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

label span p,
.hs-input::placeholder {
    font-size: 0.8rem;
    font-weight: 300;
}

.hs-button {
    color: white;
    border-radius: 0.25rem;
    background-color: #1e2dbe;
    padding: 0.7rem 1rem !important;
    margin-top: 3rem;
}

.input textarea {
    height: 7.5rem;
    margin-top: 0.5rem;
}

.hs-form-booleancheckbox-display .hs-input {
    width: 1rem !important;
    height: 1rem;
    margin-top: 0.1rem;
    margin-right: 0.5rem;
    border-radius: 2px;
    border: 0.5px solid #000 !important;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.05) !important;
    background-color: transparent !important;
}

/* Popup */
.leaflet-popup-content-wrapper {
    background-color: #cce1ff !important;
    font-size: 12px;
    color: #230050;
}

.leaflet-popup-tip-container,
.leaflet-container a.leaflet-popup-close-button {
    display: none;
}

.MuiAutocomplete-hasPopupIcon.css-mg6pq5-MuiAutocomplete-root
    .MuiOutlinedInput-root,
.MuiAutocomplete-hasClearIcon.css-mg6pq5-MuiAutocomplete-root
    .MuiOutlinedInput-root {
    padding-right: 0 !important;
}
